<template>
  <div>
    <nlp-phrase></nlp-phrase>
  </div>
</template>

<script>
import NlpPhrase from '@/components/widgets/NlpPhrase';
import store from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'nlp_phrase_page',
  data () {
    return {};
  },
  components: {
    NlpPhrase,
  },
  created: function () {},
  mounted () {},
  destroyed () {},
  methods: {},
  watch: {}
};

</script>

<style type="text/css"></style>

